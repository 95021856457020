import React from "react"
import { Link } from "gatsby"

import service2Icon from "../../assets/images/icons/cont-integration.png"
import service3Icon from "../../assets/images/icons/cloud-server.png"
import service4Icon from "../../assets/images/icons/agile.png"
import service5Icon from "../../assets/images/icons/risk.png"
import service6Icon from "../../assets/images/icons/testing.png"

const ServicesOne = () => {
  return (
    <section className="solutions-area pt-100 pb-70">
      <div className="container">
        <div className="section-title">
          <h2>Development Governance</h2>
          <p>
            Software Development in large projects is a time consuming and
            complicated process. With our project governance experience, we are
            in a position to bring our customers tools to achieve higher
            efficiency, and significantly reduce the risk of a project failure.
          </p>
          <h3>Here is what we can do:</h3>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="single-solutions-box">
              <div className="icon">
                <i className="flaticon-team"></i>
              </div>
              <h3>
                {/* <Link to="/sd-architecture-governance"> */}
                Architecture Governance
                {/* </Link> */}
              </h3>
              <p>
                Management of architecture decision process across entire
                organisation. Standardising technology usage across entire
                company. Preparation and execution of an enterprise architecture
                strategy. Monitoring of current market trends.
              </p>

              <Link className="view-details-btn" to="/contact">
                Get Details
              </Link>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="single-solutions-box">
              <div className="icon">
                <img src={service2Icon} width="80" />
              </div>

              <h3>
                {/* <Link to="/sd-cicd-improvement"> */}
                CI/CD Improvement
                {/* </Link> */}
              </h3>

              <p>
                Preparation and deployment of CI/CD processes in software
                development. Continuous Integration & Testing Automation.
                Continuous Deployment & Test System Monitoring.
              </p>

              <Link className="view-details-btn" to="/contact">
                Get Details
              </Link>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="single-solutions-box">
              <div className="icon">
                <img src={service3Icon} width="80" />
              </div>

              <h3>
                {/* <Link to="/sd-devops"> */}
                DevOps in Cloud or On-Premise
                {/* </Link> */}
              </h3>

              <p>
                Cloud Deployment preparation using Docker, Docker Swarm,
                Kubernetes, Terraform, Ansible, Helm. Moving from On-Premise to
                Cloud. Automatic solution scaling.
              </p>

              <Link className="view-details-btn" to="/contact">
                Get Details
              </Link>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="single-solutions-box">
              <div className="icon">
                <img src={service4Icon} width="80" />
              </div>

              <h3>
                {/* <Link to="/sd-agile-mgm"> */}
                Agile Methodology
                {/* </Link> */}
              </h3>

              <p>
                Governance in all phases of software project life. Team training
                for Scrum, and Kanban software development methodologies.
                Efficient Stakeholder reporting.
              </p>

              <Link className="view-details-btn" to="/contact">
                Get Details
              </Link>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="single-solutions-box">
              <div className="icon">
                <img src={service5Icon} width="80" />
              </div>

              <h3>
                {/* <Link to="/sd-risk-mgm"> */}
                Risk Management
                {/* </Link> */}
              </h3>

              <p>
                Analysis of process, personal, technological, and financial
                risks in a software development project. Adoption of long term
                risk monitoring process in project or organization.
              </p>

              <Link className="view-details-btn" to="/contact">
                Get Details
              </Link>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="single-solutions-box">
              <div className="icon">
                <img src={service6Icon} width="80" />
              </div>

              <h3>
                {/* <Link to="/sd-release-mgm"> */}
                Test & Release Management
                {/* </Link> */}
              </h3>

              <p>
                Adoption of testing processes in software development. Automatic
                and manual tests management. Release planning and go live
                preparation.
              </p>

              <Link className="view-details-btn" to="/contact">
                Get Details
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ServicesOne
